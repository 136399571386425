import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  Divider,
  Button,
  TextField,
  Dialog,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Link, NavLink } from "react-router-dom";
import "../../../global.css";

const UserAddresses = ({
  handleCloseUserAddressesDialog,
  openUserAddressesDialog,
  handleOpenAddressDialog,
  currentCustomer,
  setSelectedAddress,
}) => {
  const [selectedNeighborhood, setSelectedNeighborhood] = useState(null);

  const selectAddress = (address) => {
    setSelectedAddress(address);
    handleCloseUserAddressesDialog();
  };

  const neighborhoods =
    currentCustomer !== null
      ? currentCustomer.addresses
        ? currentCustomer.addresses
        : []
      : [];

  return (
    <Dialog
      onClose={() => {
        handleCloseUserAddressesDialog();
      }}
      open={openUserAddressesDialog}
    >
      <Box sx={{ padding: "25px", width: { md: "400px" } }}>
        {selectedNeighborhood === null ? (
          <div className="wrapperData" style={{ marginBottom: "50px" }}>
            <Box
              lg={{
                textAlign: "center",
              }}
            >
              <Typography
                variant="h2"
                lg={{
                  width: "100%",
                  textAlign: "center",
                }}
              >
                Selecione um endereço
              </Typography>
              <Typography
                variant="h6"
                lg={{
                  width: "100%",
                  textAlign: "center",
                  marginBottom: "25px",
                }}
              >
                Endereços que você já utilizou
              </Typography>
            </Box>
            <Box style={{ marginTop: "25px" }}>
              {neighborhoods.map((address) => (
                <>
                  <Box
                    onClick={() => selectAddress(address)}
                    className="neighborhood_box"
                  >
                    <Box display="flex" justifyContent="flex-start">
                      <AddIcon
                        width={10}
                        color="#CCCCCC"
                        style={{ marginRight: "10px" }}
                      />
                      <Box>
                        <Box>
                          <Typography variant="h6">{`${address.street}, ${address.number}`}</Typography>
                        </Box>
                        <Box>
                          <Typography variant="h6">{`${address.neighborhood}, ${address.city}`}</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </>
              ))}
            </Box>
            <Box style={{ width: "100%" }}>
              <Button
                style={{ marginTop: "15px" }}
                onClick={handleOpenAddressDialog}
                fullWidth
                variant="contained"
              >
                Cadastrar novo
              </Button>
            </Box>
          </div>
        ) : (
          <div className="wrapperData" style={{ marginBottom: "50px" }}></div>
        )}
      </Box>
    </Dialog>
  );
};
export default UserAddresses;
