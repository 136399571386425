import React from "react";
import { useEffect, useState, useRef } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Chip,
  Typography,
  TextField,
  Grid,
  Box,
} from "@mui/material";
import { Link, NavLink } from "react-router-dom";
import { useOutletContext, useParams } from "react-router-dom";
import { useApp } from "../../../context/appContext";
import locations from "../../../services/locations";
import { IngredientsBowl } from "../../../assets/svgIcons/icons";
import ImageCard from "./imageCard";
import currency from "currency-formatter";
import { SmsFailedSharp } from "@mui/icons-material";
import "./productListStyles.css";
import ProductPrice from "./productPrice";
import Navbar from "../Navbar";
import ImageOffer from "./imageOffer";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import SliderPrice from "./sliderPrice";

const NormalOfferList = ({ newProductsList, restaurantName }) => {
  return (
    <Grid container spacing={1}>
      {newProductsList
        .filter((p) => p.isOffer)
        .map((product) => (
          <Grid
            style={{ margin: "0", padding: "0" }}
            key={product.id}
            item
            xs={12}
            lg={6}
          >
            <Link
              to={`/${restaurantName}/produto/${product.id}`}
              style={{ textDecoration: "none" }}
            >
              <Card
                variant="elevation"
                className="mobileItem"
                xs={{
                  margin: 0,
                }}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingBottom: "0",
                  p: 0,
                  borderRadius: "5px",
                }}
              >
                <Box>
                  <CardContent>
                    <Typography
                      sx={{
                        fontWeight: "600",
                      }}
                    >
                      {product.title}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: "15px",
                        fontWeight: "500",
                      }}
                    >
                      {product.description && product.description.length > 60
                        ? `${product.description.substring(0, 60)}...`
                        : product.description}
                    </Typography>

                    <ProductPrice product={product} />
                    {product.isOffer && (
                      <Chip
                        sx={{
                          pl: "4px",
                          pr: "4px",
                          backgroundColor: "#fc4b6c",
                          color: "#fff",
                          borderRadius: "5px",
                        }}
                        size="small"
                        label="OFERTA"
                      />
                    )}
                  </CardContent>
                </Box>
                <Box>
                  <ImageCard product={product} />
                </Box>
              </Card>
            </Link>
          </Grid>
        ))}
    </Grid>
  );
};

const OffersList = (props) => {
  const { restaurantName } = useParams();

  const [searchTerm, setSearchTerm] = useOutletContext();

  const { state } = useApp();

  const observerRefs = useRef([]);

  const offerDisplay = 1;

  const newProductsList = state.products
    .sort((a, b) => b.price - a.price)
    .map((prod) => {
      ////console.log(prod);
      if (prod.offer) {
        return {
          id: prod._id,
          title: prod.name,
          image: prod.image,
          description: prod.description,
          price: prod.price,
          complementGroups: state.complementGroups
            .filter((cg) => prod.complements.includes(cg._id))
            .map((cgroup) => {
              return {
                _id: cgroup._id,
                name: cgroup.name,
                mandatory: cgroup.mandatory,
                pdvCode: cgroup.pdvCode,
                rules: {
                  min: cgroup.minQuantity,
                  max: cgroup.maxQuantity,
                },
                complements: state.complements.filter((c) =>
                  cgroup.complements.includes(c._id)
                ),
                active: cgroup.active,
              };
            }),
          isOffer: true,
          offerPrice: prod.offerPrice,
        };
      } else {
        return {
          id: prod._id,
          title: prod.name,
          image: prod.image,
          description: prod.description,
          price: prod.price,
          complementGroups: state.complementGroups
            .filter((cg) => prod.complements.includes(cg._id))
            .map((cgroup) => {
              return {
                _id: cgroup._id,
                name: cgroup.name,
                mandatory: cgroup.mandatory,
                pdvCode: cgroup.pdvCode,
                rules: {
                  min: cgroup.minQuantity,
                  max: cgroup.maxQuantity,
                },
                complements: state.complements.filter((c) =>
                  cgroup.complements.includes(c._id)
                ),
                active: cgroup.active,
              };
            }),
        };
      }
    });

  /* useEffect(() => {
    //console.log("offer list", newProductsList);
  }, []); */

  return (
    <>
      <Box>
        <Typography
          variant="h3"
          sx={{
            margin: "15px",
            color: "primary.main",
          }}
        >
          OFERTAS
        </Typography>
      </Box>
      {offerDisplay === 0 ? (
        <NormalOfferList
          newProductsList={newProductsList}
          restaurantName={restaurantName}
        />
      ) : (
        <>
          <Box
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "block",
                lg: "block",
              },
            }}
          >
            <NormalOfferList
              newProductsList={newProductsList}
              restaurantName={restaurantName}
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              overflowX: "auto",
              display: {
                sm: "flex",
                md: "none",
              },
              listStyleType: "none",
              whiteSpace: "nowrap",
              padding: 0,
              margin: 0,
            }}
          >
            {newProductsList
              .filter((p) => p.isOffer)
              .map((product) => (
                <Link
                  to={`/${restaurantName}/produto/${product.id}`}
                  style={{ textDecoration: "none" }}
                >
                  <Card
                    className="mobileItem"
                    xs={{
                      margin: 0,
                    }}
                    sx={{
                      justifyContent: "space-between",
                      paddingBottom: "0",
                      p: 0,
                      width: "140px",
                      borderRadius: "5px",
                      display: "inline-block",
                      padding: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <Box>
                      <ImageOffer product={product} />
                    </Box>
                    <Box>
                      <CardContent
                        sx={{
                          textAlign: "left",
                          paddingBottom: "0",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "600",
                            textAlign: "left",
                          }}
                        >
                          {product.title.length > 12
                            ? `${product.title.substring(0, 12)}...`
                            : product.title}
                        </Typography>
                        <SliderPrice product={product} />
                        {/* {product.isOffer && (
                          <Chip
                            sx={{
                              pl: "4px",
                              pr: "4px",
                              backgroundColor: "#fc4b6c",
                              color: "#fff",
                              borderRadius: "5px",
                              marginTop: "5px",
                            }}
                            size="small"
                            label="OFERTA IMPERDÍVEL"
                          />
                        )} */}
                      </CardContent>
                    </Box>
                  </Card>
                </Link>
              ))}
          </Box>
        </>
      )}
    </>
  );
};

export default OffersList;
