import React, { useEffect } from "react";
import { useRoutes, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { baseTheme } from "./assets/global/Theme-variable";
import Themeroutes from "./routes/Router";
import { AppProvider } from "./context/appContext";
import ScrollToTop from "./utils/scrollToTop";

const App = () => {
  const routing = useRoutes(Themeroutes);
  const theme = baseTheme;

  const location = useLocation();

  //console.log("location", location.pathname);

  useEffect(() => {
    console.log("wedo - serviços");
  }, []);

  if (location.pathname.startsWith("/#/")) {
    //console.log("has hash");
  } else {
    //console.log("doesnt have hash");
  }

  return (
    <AppProvider>
      <ScrollToTop />
      <ThemeProvider theme={theme}>{routing}</ThemeProvider>
    </AppProvider>
  );
};

export default App;
