import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Menu,
  MenuItem,
  IconButton,
} from "@mui/material";

import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";

import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import {
  translateStatus,
  translateStatusColors,
} from "../../utils/translateStatus";
import moment from "moment/moment";

const options = ["Action", "Another Action", "Something else here"];

const activities = [
  {
    time: "09.50",
    color: "success.main",
    text: "Novo produto criado",
  },
  {
    time: "09.46",
    color: "secondary.main",
    text: "Pagamento recebido no valor de R$ 39,50",
  },
  {
    time: "09.47",
    color: "primary.main",
    text: "Novo pedido",
  },
  {
    time: "09.48",
    color: "warning.main",
    text: "Produto fora de estoque",
  },
  {
    time: "09.49",
    color: "error.main",
    text: "Pedido cancelado",
  },
];

const OrderTimelineMobile = ({ order }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const orderActivities = order.statuses.map((status) => {
    return {
      time: moment(status.updatedAt).format("DD/MM/YY H:m"),
      color: translateStatusColors(status.status),
      text: translateStatus(status.status),
    };
  });

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          mb: 5,
          marginTop: "15px",
        }}
      >
        <Box>
          <Typography
            sx={{
              fontWeight: "500",
              fontSize: "h4",
              marginBottom: "0",
              color: "#777e89",
            }}
            gutterBottom
          >
            Acompanhe
          </Typography>
          <Typography
            color="textSecondary"
            variant="body1"
            sx={{
              fontWeight: "400",
              fontSize: "13px",
            }}
          >
            Atualizações no seu pedido
          </Typography>
        </Box>
      </Box>
      <Timeline
        sx={{
          p: 0,
        }}
      >
        {orderActivities.map((activity) => (
          <TimelineItem key={activity.time}>
            <TimelineOppositeContent
              sx={{
                fontSize: "12px",
                fontWeight: "700",
                flex: "0",
              }}
            >
              {activity.time}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot
                variant="outlined"
                sx={{
                  borderColor: activity.color,
                }}
              />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent
              color="text.secondary"
              sx={{
                fontSize: "14px",
              }}
            >
              {activity.text}
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </>
  );
};

export default OrderTimelineMobile;
