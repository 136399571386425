import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Typography,
  ButtonGroup,
  Dialog,
  Button,
  TextField,
  Rating,
} from "@mui/material";
import { Link, NavLink, useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useApp } from "../../context/appContext";
import OrderViewHeader from "./OrderViewHeader";
import OrderItemsView from "./OrderItems";
import OrderViewFooter from "./OrderFooter";
import OrderDeliveryAddress from "./OrderDeliveryAddress";
import OrderTimeline from "./OrderTimeline";
import OrderTakeAwayAddress from "./OrderTakeAwayAddress";
import locations from "../../services/locations";
import OrderTimelineMobile from "./OrderTimelineMobile";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import StoreLogoHeader from "../../layouts/FullLayout/Sidebar/storeLogoHeader";
import StoreLogo from "../../layouts/FullLayout/Sidebar/storeLogo";

const OrderReview = ({ currentOrder }) => {
  // 2
  const {
    state,
    getData,
    postData,
    populateCompany,
    setAddress,
    populateData,
    patchData,
    setConsumeType,
    setCustomer,
  } = useApp();

  const { restaurantName, orderId } = useParams();

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const [orderBranch, setOrderBranch] = useState(null);

  ///RATING
  const [ratingItems, setRatingItems] = useState(null);
  const [ratingQuality, setRatingQuality] = useState(null);
  const [ratingDificulty, setRatingDificulty] = useState(null);
  const [ratingDeliveryTime, setRatingDeliveryTime] = useState(null);
  const [ratingPrice, setRatingPrice] = useState(null);
  const [ratingSupport, setRatingSupport] = useState(null);

  //RATING SUGGESTIONS
  const [ratingSuggestionItems, setRatingSuggestionItems] = useState(null);
  const [ratingSuggestionQuality, setRatingSuggestionQuality] = useState(null);
  const [ratingSuggestionDificulty, setRatingSuggestionDificulty] =
    useState(null);
  const [ratingSuggestionDeliveryTime, setRatingSuggestionDeliveryTime] =
    useState(null);
  const [ratingSuggestionPrice, setRatingSuggestionPrice] = useState(null);
  const [ratingSuggestionSupport, setRatingSuggestionSupport] = useState(null);

  //EXTRA FIELDS
  const [observations, setObservations] = useState(null);
  const [reasonToConsume, setReasonToConsume] = useState(null);
  const [recommendationScore, setRecommendationScore] = useState(null);

  const handleSubmitRating = () => {
    //CRIAR UM TOKEN PRO PATCH EXCLUSIVO DESSA PROPRIEDADE

    //console.log("currentOrder", currentOrder.seq);

    let user = state.customer;
    delete user.addresses;

    const ratingObj = {
      quality: {
        points: ratingQuality,
        notes: ratingSuggestionQuality,
      },
      items: { points: ratingItems, notes: ratingSuggestionItems },
      dificulty: { points: ratingDificulty, notes: ratingSuggestionDificulty },
      deliveryTime: {
        points: ratingDeliveryTime,
        notes: ratingSuggestionDeliveryTime,
      },
      price: { points: ratingPrice, notes: ratingSuggestionPrice },
      support: { points: ratingSupport, notes: ratingSuggestionSupport },
      notes: observations,
      reasonToConsume: reasonToConsume,
      recommendationScore: recommendationScore,
    };
    //console.log("ratingObj", ratingObj);

    postData(locations.reviews, {
      title: `Pedido #${currentOrder.seq}`,
      rating: ratingObj,
      company: currentOrder.company,
      seq: currentOrder.seq,
      customer: user,
      branch: currentOrder.branch,
      orderId: currentOrder._id,
    }).then((res) => {
      //console.log("review", res);
      handleCloseModal();
    });

    //THEN
    /* setTimeout(() => {
      //console.log("closing modal after then");
      handleCloseModal();
    }, 2000); */
    //PATCH DATA
  };

  function perc2color(perc, min, max) {
    var base = max - min;

    if (base == 0) {
      perc = 100;
    } else {
      perc = ((perc - min) / base) * 100;
    }
    var r,
      g,
      b = 0;
    if (perc < 50) {
      r = 255;
      g = Math.round(5.1 * perc);
    } else {
      g = 255;
      r = Math.round(510 - 5.1 * perc);
    }
    var h = r * 0x10000 + g * 0x100 + b * 0x1;
    return "#" + ("000000" + h.toString(16)).slice(-6);
  }

  function ColorLuminance(hex, lum) {
    // validate hex string
    hex = String(hex).replace(/[^0-9a-f]/gi, "");
    if (hex.length < 6) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    lum = lum || 0;

    // convert to decimal and change luminosity
    var rgb = "#",
      c,
      i;
    for (i = 0; i < 3; i++) {
      c = parseInt(hex.substr(i * 2, 2), 16);
      c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
      rgb += ("00" + c).substr(c.length);
    }

    return rgb;
  }

  const RatingButtons = () => {
    let buttons = [];

    for (let i = 0; i <= 10; i++) {
      buttons.push(
        <Box
          sx={{
            width: {
              xs: "15px",
              md: "30px",
            },
            backgroundColor:
              recommendationScore !== i
                ? "#FFFFFF"
                : ColorLuminance(perc2color(i, 0, 10), -0.15),
            borderRadius: "5px",
            textAlign: "center",
            padding: "5px 2px",
            color:
              recommendationScore === i
                ? "#FFFFFF"
                : ColorLuminance(perc2color(i, 0, 10), -0.15),
            border: `2px solid ${ColorLuminance(perc2color(i, 0, 10), -0.15)}`,
          }}
          onClick={() => {
            //console.log("recommendation rate", i);
            setRecommendationScore(i);
          }}
          size="large"
        >
          <Typography sx={{ fontWeight: "bolder" }}>{i}</Typography>
        </Box>
      );
    }

    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {buttons}
      </Box>
    );
  };

  ///RATING

  return currentOrder ? (
    <>
      <Dialog open={openModal} onClose={handleCloseModal}>
        <Box>
          {" "}
          <Box
            sx={{
              padding: "25px",
            }}
          >
            <Box
              sx={{
                textAlign: "center",
                paddingTop: "15px",
                marginBottom: "25px",
              }}
            >
              {/* <StoreLogoHeader /> */}
              <StoreLogo
                logo={
                  state.company &&
                  state.company.settings &&
                  state.company.settings.logoURL
                }
              />
            </Box>
            <Box sx={{ textAlign: "left" }}>
              <Box sx={{ marginBottom: "10px" }}>
                <Typography variant="h4" sx={{ textAlign: "center" }}>
                  Adorariamos saber sua opinião sobre
                  <br /> nosso cardápio digital!
                </Typography>
              </Box>
              <Box sx={{ marginBottom: "50px" }}>
                <Typography variant="h6" sx={{ textAlign: "center" }}>
                  Classifique de 1 a 5 estrelas cada tópico. Ao final, se
                  desejar, nos escreva uma mensagem contato como foi sua
                  experiência :)
                </Typography>
              </Box>
              {/* ITEM 1 */}
              <Box sx={{ marginBottom: "50px" }}>
                <Box>
                  <Typography variant="h4">
                    1 - Como você avalia o nosso{" "}
                    <span style={{ fontWeight: "bolder" }}>ATENDIMENTO?</span>
                  </Typography>
                </Box>
                <Rating
                  onChange={(e, newValue) => {
                    //console.log("rating", newValue);
                    setRatingSupport(newValue);
                  }}
                  size="large"
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    fontSize: "50px",
                  }}
                />
                {ratingSupport && ratingSupport < 5 && (
                  <Box>
                    <Typography variant="h5" sx={{ marginBottom: "10px" }}>
                      O que motivou a sua nota?
                    </Typography>
                    <TextField
                      fullWidth
                      placeholder="Digite sua resposta"
                      onChange={(e) => {
                        setRatingSuggestionSupport(e.target.value);
                      }}
                    />
                  </Box>
                )}
              </Box>
              {/* ITEM 1 */}
              <Box sx={{ marginBottom: "50px" }}>
                <Box>
                  <Typography variant="h4">
                    2 - Como você avalia a{" "}
                    <span style={{ fontWeight: "bold" }}>
                      APRESENTAÇÃO DOS PRODUTOS ENTREGUES
                    </span>
                    ?
                  </Typography>
                </Box>
                <Rating
                  onChange={(e, newValue) => {
                    //console.log("rating", newValue);
                    setRatingItems(newValue);
                  }}
                  size="large"
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    fontSize: "50px",
                  }}
                />
                {ratingItems && ratingItems < 5 && (
                  <Box>
                    <Typography variant="h5" sx={{ marginBottom: "10px" }}>
                      O que motivou a sua nota?
                    </Typography>
                    <TextField
                      fullWidth
                      placeholder="Digite sua resposta"
                      onChange={(e) => {
                        setRatingSuggestionItems(e.target.value);
                      }}
                    />
                  </Box>
                )}
              </Box>
              {/* ITEM 1 */}
              <Box sx={{ marginBottom: "50px" }}>
                <Box>
                  <Typography variant="h4">
                    3 - Como você avalia a{" "}
                    <span style={{ fontWeight: "bolder" }}>
                      QUALIDADE DOS NOSSOS PRODUTOS
                    </span>
                    ?
                  </Typography>
                </Box>
                <Rating
                  onChange={(e, newValue) => {
                    //console.log("rating", newValue);
                    setRatingQuality(newValue);
                  }}
                  size="large"
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    fontSize: "50px",
                  }}
                />
                {ratingQuality && ratingQuality < 5 && (
                  <Box>
                    <Typography variant="h5" sx={{ marginBottom: "10px" }}>
                      O que motivou a sua nota?
                    </Typography>
                    <TextField
                      fullWidth
                      placeholder="Digite sua resposta"
                      onChange={(e) => {
                        setRatingSuggestionQuality(e.target.value);
                      }}
                    />
                  </Box>
                )}
              </Box>
              {/* ITEM 1 */}
              <Box sx={{ marginBottom: "50px" }}>
                <Box>
                  <Typography variant="h4">
                    4 - Como você avalia a{" "}
                    <span style={{ fontWeight: "bold" }}>
                      FACILIDADE EM REALIZAR O SEU PEDIDO
                    </span>
                    ?
                  </Typography>
                </Box>
                <Rating
                  onChange={(e, newValue) => {
                    //console.log("rating", newValue);
                    setRatingDificulty(newValue);
                  }}
                  size="large"
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    fontSize: "50px",
                  }}
                />
                {ratingDificulty && ratingDificulty < 5 && (
                  <Box>
                    <Typography variant="h5" sx={{ marginBottom: "10px" }}>
                      O que motivou a sua nota?
                    </Typography>
                    <TextField
                      fullWidth
                      placeholder="Digite sua resposta"
                      onChange={(e) => {
                        setRatingSuggestionDificulty(e.target.value);
                      }}
                    />
                  </Box>
                )}
              </Box>

              {/* ITEM 1 */}
              <Box sx={{ marginBottom: "50px" }}>
                <Box>
                  <Typography variant="h4">
                    5 - Como você avalia nosso{" "}
                    <span style={{ fontWeight: "bolder" }}>
                      TEMPO DE ENTREGA
                    </span>
                    ?
                  </Typography>
                </Box>
                <Rating
                  onChange={(e, newValue) => {
                    //console.log("rating", newValue);
                    setRatingDeliveryTime(newValue);
                  }}
                  size="large"
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    fontSize: "50px",
                  }}
                />
                {ratingDeliveryTime && ratingDeliveryTime < 5 && (
                  <Box>
                    <Typography variant="h5" sx={{ marginBottom: "10px" }}>
                      O que motivou a sua nota?
                    </Typography>
                    <TextField
                      fullWidth
                      placeholder="Digite sua resposta"
                      onChange={(e) => {
                        setRatingSuggestionDeliveryTime(e.target.value);
                      }}
                    />
                  </Box>
                )}
              </Box>
              {/* ITEM 1 */}
              <Box sx={{ marginBottom: "50px" }}>
                <Box>
                  <Typography variant="h4">
                    6 - Como você avalia nosso{" "}
                    <span style={{ fontWeight: "bolder" }}>
                      CUSTO-BENEFÍCIO
                    </span>
                    ?
                  </Typography>
                </Box>
                <Rating
                  onChange={(e, newValue) => {
                    //console.log("rating", newValue);
                    setRatingPrice(newValue);
                  }}
                  size="large"
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    fontSize: "50px",
                  }}
                />
                {ratingPrice && ratingPrice < 5 && (
                  <Box>
                    <Typography variant="h5" sx={{ marginBottom: "10px" }}>
                      O que motivou a sua nota?
                    </Typography>
                    <TextField
                      fullWidth
                      placeholder="Digite sua resposta"
                      onChange={(e) => {
                        setRatingSuggestionPrice(e.target.value);
                      }}
                    />
                  </Box>
                )}
              </Box>
              <Box sx={{ marginBottom: "50px" }}>
                <Box>
                  <Typography
                    variant="h4"
                    sx={{
                      marginBottom: "10px",
                    }}
                  >
                    7 - O que faria você consumir na loja do{` `}
                    {state.branch.name}?
                  </Typography>
                </Box>
                <TextField
                  fullWidth
                  onChange={(e) => {
                    setReasonToConsume(e.target.value);
                  }}
                />
              </Box>
              <Box sx={{ marginBottom: "50px" }}>
                <Box>
                  <Typography
                    variant="h4"
                    sx={{
                      marginBottom: "10px",
                    }}
                  >
                    8 - Em uma escala de 0 a 10, o quanto você recomendaria o{" "}
                    {state.branch.name} para um amigo ou familiar?
                  </Typography>
                </Box>
                <RatingButtons />
              </Box>
              <Box sx={{ marginBottom: "50px" }}>
                <Box>
                  <Typography
                    variant="h4"
                    sx={{
                      marginBottom: "15px",
                    }}
                  >
                    9 - Informe aqui sua sugestão ou elogio
                  </Typography>
                </Box>
                <TextField
                  multiline
                  fullWidth
                  rows={4}
                  sx={{ marginBottom: "10px" }}
                  onChange={(e) => setObservations(e.target.value)}
                />
              </Box>
              <Button
                fullWidth
                variant="contained"
                onClick={() => handleSubmitRating()}
                disabled={
                  !ratingItems ||
                  !ratingDificulty ||
                  !ratingDeliveryTime ||
                  !ratingPrice ||
                  !ratingSupport ||
                  !ratingQuality
                }
              >
                Concluir
              </Button>
            </Box>
          </Box>
        </Box>
      </Dialog>
      {/* {!currentOrder.orderReview && (
        <Button
          sx={{
            marginTop: "15px",
          }}
          fullWidth
          variant="contained"
          onClick={() => handleOpenModal()}
        >
          Avaliar meu pedido
        </Button>
      )} */}
    </>
  ) : (
    <Typography>Pedido não encontrado</Typography>
  );
};

export default OrderReview;
