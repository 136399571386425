import io from "socket.io-client";
import feathers from "@feathersjs/feathers";
import socketio from "@feathersjs/socketio-client";
import authentication from "@feathersjs/authentication-client";
import locations from "./locations";

//http://185.217.126.3/

const socket = io(
  process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : locations.baseURL,
  {
    transports: ["websocket"],
    forceNew: true,
  }
);
const client = feathers();

client.configure(
  socketio(socket, {
    timeout: 15000,
  })
);

client.configure(authentication());

export default client;
export { socket };
