import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Box,
  Typography,
  Divider,
  Button,
  TextField,
  Dialog,
} from "@mui/material";
import { useApp } from "../../../context/appContext";
import AddIcon from "@mui/icons-material/Add";
import { Link, NavLink } from "react-router-dom";
import "../../../global.css";
import {
  pointByAddress,
  addressByPoint,
  addressByCEP,
  suggestAddress,
} from "../../../utils/geoLocation";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import brazilianStates from "../../../utils/brazilianStates";
import OtherHousesIcon from "@mui/icons-material/OtherHouses";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import InputMask from "react-input-mask";
import locations from "../../../services/locations";

const AddressDialog = ({
  handleCloseAddressDialog,
  openAddressDialog,
  handleOpenAddressDialog,
  currentCustomer,
  setSelectedAddress,
  handleCloseUserAddressesDialog,
}) => {
  const { state, setCustomer, setAddress, patchData } = useApp();

  const [selectedNeighborhood, setSelectedNeighborhood] = useState(null);

  const [positionAddress, setPositionAddress] = useState(null);

  const [selectedSuggestion, setSelectedSuggestion] = useState(null);

  const [addressUF, setUF] = useState("Ceará");

  const [postalCodeModal, setPostalCodeModal] = useState(false);
  const [searchStreetModal, setStreetModal] = useState(false);

  const [streetName, setStreetName] = useState("");
  const [postalCode, setPostalCode] = useState("");

  const [streetSuggestions, setStreetSuggestions] = useState([]);

  const selectBairro = (bairro) => {
    setSelectedNeighborhood(bairro);
  };

  const handleUFChange = (e) => {
    setUF(e);
  };

  const handleOpenCepModal = () => {
    setPostalCodeModal(true);
  };

  const handleCloseCepModal = () => {
    setPostalCodeModal(false);
    setTimeout(() => {
      setPostalCode("");
      setStreetSuggestions([]);
    }, [500]);
  };

  const handleOpenStreetModal = () => {
    setStreetModal(true);
  };

  const handleCloseStreetModal = () => {
    setStreetModal(false);
    setTimeout(() => {
      setStreetName("");
      setStreetSuggestions([]);
    }, [500]);
  };

  const [formInput, setFormInput] = useState({
    street: "",
    number: "",
    neighborhood: "",
    complement: "",
    reference: "",
    city: "",
    state: addressUF,
    postalCode: "",
  });

  const handleFormInput = (e) => {
    setFormInput({
      ...formInput,
      [e.target.name]: e.target.value,
    });
  };

  const handlePosition = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          ////console.log(position.coords.latitude);
          ////console.log(position.coords.longitude);
          addressByPoint(
            position.coords.latitude,
            position.coords.longitude
          ).then((res) => {
            setUF(res.address.state);
            ////console.log("res do reverse geocoding", res.address);
            setFormInput({
              street: res.address.road,
              number: res.address.house_number,
              neighborhood: res.address.suburb,
              complement: "",
              reference: "",
              city: res.address.city,
              state: res.address.state,
              postalCode: res.address.postcode,
            });
          });
        },
        function (error) {
          console.error("Error Code = " + error.code + " - " + error.message);
        }
      );
    }
  };

  const handleCEPSearch = async (input) => {
    const result = await addressByCEP(input);
    ////console.log("cep result", result);
    setStreetSuggestions(result);
  };

  const handleStreetSearch = async (input) => {
    const result = await suggestAddress(input);
    ////console.log("formmated", result);
    setStreetSuggestions(result);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (streetName && streetName !== "") {
        handleStreetSearch(streetName);
      } else {
        setStreetSuggestions([]);
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [streetName]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (postalCode && postalCode !== "") {
        handleCEPSearch(postalCode);
      } else {
        setStreetSuggestions([]);
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [postalCode]);

  useEffect(() => {
    ////console.log("streetSuggestions", streetSuggestions);
  }, [streetSuggestions]);

  const saveAddress = async () => {
    let address = {
      street: formInput.street,
      number: formInput.number,
      complement: formInput.complement,
      neighborhood: formInput.neighborhood,
      city: formInput.city,
      state: addressUF,
      postalCode: formInput.postalCode,
    };

    let newCustomer = currentCustomer;

    ////console.log(address);

    //ADICIONAR UM LOADING AQIO ENQUANTO CAPTURA AS CORDENADAS

    //CHECAR AQUI SE JA TEM DADOS DAS BUSCAS DE GPS, CEP OU RUA, SE TIVER, PULAR ESSA PARTE
    await pointByAddress(
      `${address.street} ${address.number}, ${address.neighborhood}, ${address.city} ${address.state}`
    ).then((res) => {
      //////console.log("resposta geocode", res);
      address.coordinates = res;
    });

    address.reference = formInput.reference;

    newCustomer.addresses.push(address);

    ////console.log("address", address);

    //PATCH CUSTOMER

    if (state.customer) {
      patchData(locations.customers, state.customer._id, {
        addresses: newCustomer.addresses,
        token: newCustomer.token,
      })
        .then((res) => {
          ////console.log("customer updated", res);
        })
        .catch((e) => {
          ////console.log("patch error", e);
        });
    }

    //PATCH CUSTOMER

    ////console.log("newCustomer address", newCustomer);
    setCustomer(newCustomer);

    setAddress(address);

    handleCloseAddressDialog();
    handleCloseUserAddressesDialog();
  };

  const handleSelectSuggestion = (street) => {
    setSelectedSuggestion(street);
    handleCloseCepModal();
    handleCloseStreetModal();
  };

  const populateFields = (address) => {
    if (address && address.address) {
      const addressFields = address.address;

      //ERRO AQUI - PQ NO CEP AINDA N VEM O OBJETO ADDRESS
      if (addressFields.state) {
        setUF(addressFields.state);
      }
      //////console.log("res do reverse geocoding", res.address);
      setFormInput({
        street: addressFields.street || "",
        number: addressFields.number || "",
        neighborhood: addressFields.neighborhood || "",
        complement: "",
        reference: "",
        city: addressFields.city || "",
        state: addressFields.state || "",
        postalCode: addressFields.postcode || "",
      });
    }
  };

  useEffect(() => {
    ////console.log("setar dados aqui - selectedSuggestion", selectedSuggestion);
    populateFields(selectedSuggestion);
  }, [selectedSuggestion]);

  const SuggestedAddresses = () => {
    return (
      <Box
        sx={{
          marginBottom: "15px",
        }}
      >
        {streetSuggestions.map((street) => (
          <Box
            onClick={() => handleSelectSuggestion(street)}
            sx={{
              transition: "0.2s all",
              paddingBottom: "10px",
              paddingTop: "10px",
              paddingLeft: "10px",
              paddingRight: "10px",
              borderBottom: "1px solid #CCC",
              cursor: "pointer",
              ":hover": {
                backgroundColor: "#f1f1f1",
              },
            }}
          >
            <Typography sx={{ fontSize: "12px" }}>{street.name}</Typography>
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <>
      <Dialog open={postalCodeModal} onClose={() => handleCloseCepModal()}>
        <Box
          sx={{
            padding: "15px",
            width: {
              md: "400px",
            },
          }}
        >
          <Typography variant="h4" sx={{ marginBottom: "15px" }}>
            Informe seu CEP
          </Typography>
          <InputMask
            //style={{ marginTop: "5px" }}
            mask="99999-999"
            name="postalCode"
            value={postalCode}
            disabled={false}
            maskChar=" "
            onChange={(e) => setPostalCode(e.target.value)}
          >
            {() => (
              <TextField
                fullWidth
                sx={{ marginBottom: "15px", fontSize: "24px" }}
              />
            )}
          </InputMask>

          <SuggestedAddresses />
          <Button variant="contained">Confirmar</Button>
          {/* RESERVE - COORDINATES TO ADDRESS */}
          {/* <Button variant="contained">Teste Inverso</Button> */}
        </Box>
      </Dialog>
      <Dialog open={searchStreetModal} onClose={() => handleCloseStreetModal()}>
        <Box
          sx={{
            padding: "15px",
            width: {
              md: "400px",
            },
          }}
        >
          <Typography variant="h4" sx={{ marginBottom: "15px" }}>
            Buscar por rua
          </Typography>
          <TextField
            fullWidth
            onChange={(e) => setStreetName(e.target.value)}
            sx={{ marginBottom: "15px", fontSize: "24px" }}
          />

          <SuggestedAddresses />

          <Button sx={{ marginTop: "25px" }} variant="contained">
            Confirmar
          </Button>
        </Box>
      </Dialog>
      <Dialog
        onClose={() => {
          handleCloseAddressDialog();
          setSelectedNeighborhood(null);
        }}
        open={openAddressDialog}
      >
        <Box sx={{ padding: "25px", width: { md: "400px" } }}>
          <div className="wrapperData" style={{ marginBottom: "50px" }}>
            <Box
              lg={{
                textAlign: "center",
              }}
            >
              <Typography
                variant="h2"
                lg={{
                  width: "100%",
                  textAlign: "center",
                }}
              >
                Informe seu endereço
              </Typography>
              <Typography
                variant="h6"
                lg={{
                  width: "100%",
                  textAlign: "center",
                  marginBottom: "25px",
                }}
              >
                Todos os campos são obrigatórios com exceção ao{" "}
                <strong>complemento</strong> (caso não possua)
              </Typography>
            </Box>
            <Box
              sx={{
                marginTop: "15px",
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <Button
                    fullWidth
                    variant="outlined"
                    startIcon={<GpsFixedIcon />}
                    onClick={() => handlePosition()}
                  >
                    GPS
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    fullWidth
                    variant="outlined"
                    startIcon={<OtherHousesIcon />}
                    onClick={() => handleOpenCepModal()}
                  >
                    CEP
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    fullWidth
                    variant="outlined"
                    startIcon={<FmdGoodIcon />}
                    onClick={() => handleOpenStreetModal()}
                  >
                    Rua
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <TextField
                style={{ marginTop: "5px" }}
                id="default-textarea"
                value={formInput.street}
                autoComplete="off"
                label="Rua"
                name="street"
                onChange={(e) => handleFormInput(e)}
                variant="outlined"
                fullWidth
                sx={{
                  mb: 2,
                }}
              />
            </Box>
            <Box>
              <TextField
                style={{ marginTop: "5px" }}
                id="default-textarea"
                autoComplete="off"
                value={formInput.number}
                label="Número da casa/edifícil"
                name="number"
                onChange={(e) => handleFormInput(e)}
                variant="outlined"
                fullWidth
                sx={{
                  mb: 2,
                }}
              />
            </Box>
            <Box>
              <TextField
                style={{ marginTop: "5px" }}
                id="default-textarea"
                autoComplete="off"
                label="Complemento"
                name="complement"
                onChange={(e) => handleFormInput(e)}
                variant="outlined"
                fullWidth
                sx={{
                  mb: 2,
                }}
              />
            </Box>
            <Box>
              <TextField
                style={{ marginTop: "5px" }}
                id="default-textarea"
                autoComplete="off"
                label="Ponto de referência"
                name="reference"
                onChange={(e) => handleFormInput(e)}
                variant="outlined"
                fullWidth
                sx={{
                  mb: 2,
                }}
              />
            </Box>
            <Box>
              <TextField
                style={{ marginTop: "5px" }}
                id="default-textarea"
                autoComplete="off"
                label="Bairro"
                value={formInput.neighborhood}
                variant="outlined"
                name="neighborhood"
                onChange={(e) => handleFormInput(e)}
                fullWidth
                sx={{
                  mb: 2,
                }}
              />
            </Box>
            <Box>
              <TextField
                id="default-textarea"
                autoComplete="off"
                label="CEP"
                value={formInput.postalCode}
                variant="outlined"
                name="postalCode"
                onChange={(e) => handleFormInput(e)}
                fullWidth
                sx={{
                  mb: 2,
                }}
              />
            </Box>
            <Box>
              <TextField
                id="default-textarea"
                autoComplete="off"
                label="Cidade"
                variant="outlined"
                value={formInput.city}
                name="city"
                onChange={(e) => handleFormInput(e)}
                fullWidth
                sx={{
                  mb: 2,
                }}
              />
            </Box>
            <Box>
              {/* STATE SELECT BY LIST */}
              <FormControl
                fullWidth
                style={{ marginTop: "5px", marginBottomm: "15px" }}
              >
                <InputLabel id="demo-simple-select-label">Estado</InputLabel>
                <Select
                  onChange={(e) => handleUFChange(e.target.value)}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Estado"
                  defaultValue={`Ceará`}
                  value={addressUF}
                >
                  {brazilianStates.map((uf) => (
                    <MenuItem value={uf}>
                      <Typography>{uf}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* <TextField
              style={{ marginTop: "5px" }}
              id="default-textarea"
              autoComplete="off"
              label="Estado"
              value={formInput.state}
              variant="outlined"
              name="state"
              onChange={(e) => handleFormInput(e)}
              fullWidth
              sx={{
                mb: 2,
              }}
            /> */}
            </Box>
            {/* <Box
            style={{ width: "100%", marginBottom: "15px", marginTop: "25px" }}
          >
            <Button
              onClick={() => setSelectedNeighborhood(null)}
              fullWidth
              variant="outlined"
            >
              Escolher outro bairro
            </Button>
          </Box> */}
            <Box style={{ width: "100%", marginTop: "15px" }}>
              <Button
                onClick={() => saveAddress()}
                fullWidth
                variant="contained"
              >
                Salvar Endereço
              </Button>
            </Box>
          </div>
        </Box>
      </Dialog>
    </>
  );
};
export default AddressDialog;
