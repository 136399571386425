import React from "react";
import { Grid, Box, Typography, Button, Stack, Chip } from "@mui/material";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import moment from "moment/moment";
import {
  translateStatus,
  translateStatusColors,
} from "../../utils/translateStatus";

const OrderViewHeader = ({ order }) => {
  return (
    <Grid item xs={12} lg={12} style={{ marginTop: "50px" }}>
      <Stack direction="row" spacing={1} justifyContent="space-between">
        <Box align="left">
          <Typography variant="h6" style={{ marginBottom: "15px" }}>
            Este pedido deverá ser entregue a:
          </Typography>
          <Typography variant="h3">{order.customer.name}</Typography>
          <Typography variant="h6">{order.customer.phone}</Typography>
          <Chip
            icon={<WatchLaterIcon color="#FFFFFF" />}
            sx={{
              pl: "4px",
              pr: "4px",
              backgroundColor: "#CCC",
              color: "#fff",
              marginTop: "15px",
            }}
            size="small"
            label="Tempo de entrega: 30-40 min"
          ></Chip>
        </Box>
      </Stack>
      <Box sx={{ marginTop: "25px" }}>
        <Chip
          label={translateStatus(order.status)}
          sx={{
            borderRadius: "5px",
            height: "24px",
            textTransform: "uppercase",
            fontSize: "11px",
            letterSpacing: "0.8px",
            marginTop: "5px",
            marginRight: "5px",
            backgroundColor: translateStatusColors(order.status),
          }}
        />
      </Box>
      {/* <Box align="left">
        <Typography variant="h3">
          #{order.seq.toString().padStart(4, "0")}
        </Typography>
        <Typography variant="h6">
          <span style={{ fontWeight: "bolder" }}>Data e hora do pedido:</span>{" "}
          {moment(order.createdAt).format("DD/MM/YY H:m")}
        </Typography>
        <Chip
          icon={<WatchLaterIcon color="#FFFFFF" />}
          sx={{
            pl: "4px",
            pr: "4px",
            backgroundColor: "#000",
            color: "#fff",
            marginTop: "15px",
          }}
          size="small"
          label="Tempo de entrega: 30-40 min"
        ></Chip>
      </Box> */}
    </Grid>
  );
};
export default OrderViewHeader;
