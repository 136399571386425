import React, { useState, useEffect } from "react";
import { Grid, Box, Typography, Radio, Divider, Alert } from "@mui/material";
import { useApp } from "../../../context/appContext";
import calculateTotalPrice from "../../../utils/calculateTotalCart";
import calculateCouponDiscount from "../../../utils/calculateCouponDiscount";
import AddCardIcon from "@mui/icons-material/AddCard";

const PaymentMethods = ({
  branch,
  selectedValue,
  handlePaymentChange,
  selectedIfoodValue,
  handleIfoodPaymentChange,
  translatePayment,
}) => {
  const { state } = useApp();

  const defaultPaymentChecked = (name) => {
    const validPayment = branch.paymentMethodsDefault.filter(
      (p) => p.name === name && p.active
    );

    if (validPayment.length) {
      return true;
    }
  };

  const cartSubtotal = calculateTotalPrice(state.cart);

  const totalAmount =
    state.appliedCoupon && state.appliedCoupon.rules.conditionalRules
      ? cartSubtotal
      : state.appliedCoupon
      ? calculateTotalPrice(state.cart) -
        calculateCouponDiscount(
          calculateTotalPrice(state.cart),
          state.appliedCoupon
        )
      : calculateTotalPrice(state.cart);

  useEffect(() => {
    //console.log("totalAmount", totalAmount);
  }, []);

  return (
    <Grid item xs={12} lg={12} style={{ marginTop: "25px" }}>
      <>
        <Box
          style={{
            backgroundColor: "#f5f5f5",
            padding: "15px",
            borderRadius: "5px",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: "600",
            }}
          >
            <AddCardIcon
              sx={{
                marginRight: "5px",
                marginBottom: "-8px",
              }}
            />
            Selecione a forma de pagamento
          </Typography>
        </Box>
        <Box
          style={{
            marginBottom: "15px",
            border: "1px solid #f5f5f5",
            padding: "15px",
          }}
        >
          {state.consumeType &&
          state.consumeType === 1 &&
          state.deliveryInfo &&
          state.deliveryInfo.type &&
          state.deliveryInfo.type === "ifood" &&
          state.deliveryInfo.quote ? (
            <>
              {state.deliveryInfo.quote.paymentMethods.map((payment) => (
                <>
                  <Grid container spacing={0}>
                    <Grid item xs={10} lg={10}>
                      <Typography variant="h6">
                        {payment.brand
                          ? `${payment.brand} - ${translatePayment(
                              payment.method
                            )}`
                          : `${translatePayment(payment.method)}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} lg={2}>
                      <Box display="flex" justifyContent="flex-end">
                        <Radio
                          checked={selectedIfoodValue === payment.id}
                          onChange={handleIfoodPaymentChange}
                          value={payment.id}
                          name="radio-buttons"
                          inputProps={{ "aria-label": "A" }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Divider
                    style={{ marginTop: "15px", marginBottom: "15px" }}
                  />
                </>
              ))}
            </>
          ) : (
            <>
              {defaultPaymentChecked("cash") && (
                <>
                  <Grid container spacing={0}>
                    <Grid item xs={10} lg={10}>
                      <Typography variant="h6">Dinheiro</Typography>
                    </Grid>
                    <Grid item xs={2} lg={2}>
                      <Box display="flex" justifyContent="flex-end">
                        <Radio
                          checked={selectedValue === "cash"}
                          onChange={handlePaymentChange}
                          value="cash"
                          name="radio-buttons"
                          inputProps={{ "aria-label": "A" }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Divider
                    style={{ marginTop: "15px", marginBottom: "15px" }}
                  />
                </>
              )}
              {defaultPaymentChecked("pix") && (
                <>
                  <Grid container spacing={0}>
                    <Grid item xs={10} lg={10}>
                      <Typography variant="h6">Pix</Typography>
                    </Grid>
                    <Grid item xs={2} lg={2}>
                      <Box display="flex" justifyContent="flex-end">
                        <Radio
                          checked={selectedValue === "pix"}
                          onChange={handlePaymentChange}
                          value="pix"
                          name="radio-buttons"
                          inputProps={{ "aria-label": "A" }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Divider
                    style={{ marginTop: "15px", marginBottom: "15px" }}
                  />
                </>
              )}
              {defaultPaymentChecked("dynamicPix") && totalAmount >= 5 && (
                <>
                  <Grid container spacing={0}>
                    <Grid item xs={10} lg={10}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: "bolder",
                        }}
                      >
                        Pagar Online com o Pix
                      </Typography>
                    </Grid>
                    <Grid item xs={2} lg={2}>
                      <Box display="flex" justifyContent="flex-end">
                        <Radio
                          checked={selectedValue === "dynamicPix"}
                          onChange={handlePaymentChange}
                          value="dynamicPix"
                          name="radio-buttons"
                          inputProps={{ "aria-label": "A" }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  {selectedValue === "dynamicPix" && (
                    <Box>
                      <Alert severity="info">
                        <Typography
                          sx={{
                            fontWeight: "bolder",
                          }}
                        >
                          Pix Copia e Cola
                        </Typography>
                        <Typography>
                          Após concluir seu pedido, será gerado um código{" "}
                          <span
                            style={{
                              fontWeight: "bolder",
                            }}
                          >
                            Pix Copia e Cola
                          </span>{" "}
                          para pagamento.
                        </Typography>
                      </Alert>
                    </Box>
                  )}
                  <Divider
                    style={{ marginTop: "15px", marginBottom: "15px" }}
                  />
                </>
              )}
              {defaultPaymentChecked("card") && (
                <>
                  <Grid container spacing={0}>
                    <Grid item xs={10} lg={10}>
                      <Typography variant="h6">Cartão na Entrega</Typography>
                    </Grid>
                    <Grid item xs={2} lg={2}>
                      <Box display="flex" justifyContent="flex-end">
                        <Radio
                          checked={selectedValue === "card"}
                          onChange={handlePaymentChange}
                          value="card"
                          name="radio-buttons"
                          inputProps={{ "aria-label": "B" }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </>
              )}
            </>
          )}
        </Box>
      </>
    </Grid>
  );
};
export default PaymentMethods;
