import React from "react";
import { Grid, Box, Typography, Skeleton } from "@mui/material";
import { IngredientsBowl } from "../../assets/svgIcons/icons";
import { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import "../dashboards/dashboard1-components/productListStyles.css";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

const HeaderBanners = ({ branch }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoaded = () => {
    ////console.log("imageLoaded");
    setImageLoaded(true);
  };

  return (
    <Grid item xs={12}>
      <Box
        sx={{
          textAlign: "center",
          marginTop: "25px",
        }}
      >
        {/* {!imageLoaded && (
          <Skeleton
            width="100%"
            height={250}
            style={{
              borderRadius: "5px",
            }}
          />
        )}
        <img
          src={`/static/fast-food-pizza.jpg`}
          onLoad={() => handleImageLoaded()}
          style={
            !imageLoaded
              ? {
                  display: "none",
                }
              : {
                  display: "block",
                  marginTop: "25px",
                  marginBottom: "100px",
                  width: "100%",
                  height: "250px",
                  objectFit: "cover",
                  borderRadius: "5px",
                }
          }
        /> */}
        <Carousel showThumbs={false} showArrows={false} showStatus={false}>
          {branch &&
            branch.bannersHeader &&
            branch.bannersHeader.map((banner) => (
              <div>
                <img
                  src={banner.url}
                  className="sampleBanners"
                  style={{
                    display: "block",
                    marginTop: { xs: "25px", md: "75px" },
                    marginBottom: "25px",
                    width: "100%",
                    height: isMobile ? "auto" : "250px",
                    objectFit: "cover",
                  }}
                />
              </div>
            ))}
        </Carousel>
      </Box>
    </Grid>
  );
};
export default HeaderBanners;
