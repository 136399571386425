const locations = {
  baseURL: "https://api.uidu.shop/",
  companies: "manager/companies",
  products: "manager/products",
  branches: "manager/branches",
  entregaFacil: "manager/entrega-facil-ifood",
  customers: "manager/customers",
  externalCustomers: "manager/externalCustomers",
  orders: "manager/orders",
  carts: "carts",
  reviews: "reviews",
  sessions: "customer-sessions",
  manageReviews: "manage-reviews",
  externalReviews: "external-reviews",
  dynamicPix: "integrations/asaas-pix",
};

export default locations;
