import React from "react";
import { Grid, Box, Typography, TextField, Button } from "@mui/material";
import { useApp } from "../../../context/appContext";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import MopedIcon from "@mui/icons-material/Moped";
import StorefrontIcon from "@mui/icons-material/Storefront";
import DoneIcon from "@mui/icons-material/Done";

const CheckoutConsumeType = ({ handleOpenUserAddressesDialog }) => {
  const { state, setConsumeType } = useApp();

  return (
    <Grid item xs={12} lg={12} style={{ marginTop: "25px" }}>
      <>
        <Box
          style={{
            backgroundColor: "#f5f5f5",
            padding: "15px",
            borderRadius: "5px",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: "600",
            }}
          >
            <NoteAltIcon
              sx={{
                marginRight: "5px",
                marginBottom: "-8px",
              }}
            />
            Como deseja consumir?
          </Typography>
        </Box>
        <Box>
          <Box
            sx={{
              marginTop: "15px",
              marginBottom: "5px",
            }}
          >
            <Button
              fullWidth
              variant="contained"
              endIcon={state.consumeType === 1 && <DoneIcon />}
              onClick={() => {
                setConsumeType(1);
                handleOpenUserAddressesDialog();
              }}
              sx={{
                textTransform: "uppercase",
                letterSpacing: "1px",
              }}
            >
              <MopedIcon
                sx={{
                  width: "18px",
                  color: "#FFFFFF",
                  marginRight: "5px",
                }}
              />
              <Typography>
                Quero{" "}
                <span
                  style={{
                    fontWeight: "bolder",
                  }}
                >
                  receber
                </span>
              </Typography>
            </Button>
          </Box>
          <Box>
            <Button
              fullWidth
              variant="outlined"
              onClick={() => setConsumeType(2)}
              sx={{
                textTransform: "uppercase",
                letterSpacing: "1px",
              }}
              endIcon={state.consumeType === 2 && <DoneIcon />}
            >
              <StorefrontIcon
                sx={{
                  width: "18px",
                  color: "#1a97f5",
                  marginRight: "5px",
                }}
              />
              <Typography>
                Quero{" "}
                <span
                  style={{
                    fontWeight: "bolder",
                  }}
                >
                  retirar
                </span>
              </Typography>
            </Button>
          </Box>
        </Box>
      </>
    </Grid>
  );
};
export default CheckoutConsumeType;
