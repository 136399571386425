import React from "react";
import { Grid, Box, Typography, TextField } from "@mui/material";
import { useApp } from "../../../context/appContext";
import NoteAltIcon from "@mui/icons-material/NoteAlt";

const GeneralNotes = ({ setGeneralNotes }) => {
  const { state } = useApp();

  return (
    <Grid item xs={12} lg={12} style={{ marginTop: "25px" }}>
      <>
        <Box
          style={{
            backgroundColor: "#f5f5f5",
            padding: "15px",
            borderRadius: "5px",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: "600",
            }}
          >
            <NoteAltIcon
              sx={{
                marginRight: "5px",
                marginBottom: "-8px",
              }}
            />
            {state.consumeType === 1
              ? "Observações gerais sobre a entrega"
              : "Observações gerais sobre a retirada"}
          </Typography>
        </Box>
        <Box>
          <TextField
            multiline
            onChange={(e) => setGeneralNotes(e.target.value)}
            rows={4}
            style={{ marginTop: "25px" }}
            id="default-textarea"
            label="Ex: Ponto de referência, tocar interfone etc"
            variant="outlined"
            fullWidth
            sx={{
              mb: 2,
            }}
          />
        </Box>
      </>
    </Grid>
  );
};
export default GeneralNotes;
