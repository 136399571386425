import React, { useState, useEffect } from "react";
import {
  Alert,
  Grid,
  Box,
  Typography,
  Drawer,
  IconButton,
  Button,
  Stack,
  TextField,
  Snackbar,
  Divider,
} from "@mui/material";
import currency from "currency-formatter";
import { useApp } from "../../context/appContext";
import { ArrowForwardIosRounded } from "@mui/icons-material";
import { RemoveCircle } from "@mui/icons-material";
import { Delete } from "@mui/icons-material";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import ClearIcon from "@mui/icons-material/Clear";
import moment from "moment";
import DiscountIcon from "@mui/icons-material/Discount";

const CouponInfo = () => {
  const { state, applyCoupon, clearCoupons } = useApp();

  const [couponCode, setCouponCode] = useState("");
  const [snack, setSnack] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleOpenDrawer = () => {
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };

  const checkCoupon = () => {
    const coupons = state.coupons.filter(
      (c) => c.rules.couponCode.toLowerCase() === couponCode.toLowerCase()
    );

    //FAZER A CONSULTA SE O CUPOM É ENTREGA AQUI

    if (coupons.length !== 0) {
      //console.log("cupom válido", couponCode);
      if (applyCoupon(coupons[0])) {
        //console.log("valid coupon");
      } else {
        //console.log("INVALID COUPON - CHECK CART ITEMS");
        setSnack(true);
      }
    } else {
      //console.log("cupom inválido", couponCode);
      applyCoupon(null);
    }
  };

  const removeCoupon = () => {
    //CRIAR UMA NOVA FUNCAO NO APP_CONTEXT PARA O UPDATE
    clearCoupons();
    applyCoupon(null);
    setCouponCode("");
  };

  useEffect(() => {
    if (couponCode && couponCode !== "") {
      checkCoupon();
    }
  }, [couponCode]);

  return (
    <Grid item xs={12} lg={12} style={{ marginTop: "25px" }}>
      <>
        <Drawer
          open={drawerOpen}
          onClose={handleCloseDrawer}
          anchor="right"
          PaperProps={{
            sx: {
              width: { xs: "80%", md: "500px" },
            },
          }}
        >
          <Box
            sx={{
              padding: "25px",
            }}
          >
            <Grid
              container
              spacing={1}
              sx={{
                marginBottom: "15px",
              }}
            >
              <Grid item xs={10}>
                <Typography variant="h4">Regras do Cupom</Typography>
              </Grid>
              <Grid
                item
                xs={2}
                sx={{
                  textAlign: "right",
                }}
              >
                <ClearIcon onClick={() => handleCloseDrawer()} />
              </Grid>
            </Grid>
            <Divider
              sx={{
                marginBottom: "15px",
              }}
            />
            <Box>
              <Typography variant="h5">
                {state.appliedCoupon && state.appliedCoupon.title}
              </Typography>
            </Box>
            {state.appliedCoupon && state.appliedCoupon.description && (
              <Box
                sx={{
                  marginBottom: "15px",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: "14px",
                  }}
                >
                  {state.appliedCoupon.description}
                </Typography>
              </Box>
            )}

            <Box>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bolder",
                      fontSize: "14px",
                    }}
                  >
                    Benefício:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  {state.appliedCoupon &&
                  state.appliedCoupon.reward.type === 1 ? (
                    <Typography
                      variant="h6"
                      sx={{
                        textAlign: "right",
                        fontSize: "14px",
                      }}
                    >
                      {state.appliedCoupon && state.appliedCoupon.reward.amount}
                      % de desconto
                    </Typography>
                  ) : (
                    <Typography
                      variant="h6"
                      sx={{
                        textAlign: "right",
                        fontSize: "14px",
                      }}
                    >
                      {state.appliedCoupon && state.appliedCoupon.reward.amount}{" "}
                      de desconto
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bolder",
                      fontSize: "14px",
                    }}
                  >
                    Pedido mínimo:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="h6"
                    sx={{
                      textAlign: "right",
                      fontSize: "14px",
                    }}
                  >
                    {currency.format(
                      (state.appliedCoupon &&
                        state.appliedCoupon.rules.requiredAmount) ||
                        0,
                      {
                        code: "BRL",
                      }
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bolder",
                      fontSize: "14px",
                    }}
                  >
                    Válido até:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="h6"
                    sx={{
                      textAlign: "right",
                      fontSize: "14px",
                    }}
                  >
                    {moment(
                      state.appliedCoupon && state.appliedCoupon.rules.expiresAt
                    ).format("DD/MM/YYYY")}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bolder",
                      fontSize: "14px",
                    }}
                  >
                    Limite por cliente:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="h6"
                    sx={{
                      textAlign: "right",
                      fontSize: "14px",
                    }}
                  >
                    {state.appliedCoupon &&
                      state.appliedCoupon.rules.limitByUser}
                    x
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bolder",
                      fontSize: "14px",
                    }}
                  >
                    Limite de itens:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="h6"
                    sx={{
                      textAlign: "right",
                      fontSize: "14px",
                    }}
                  >
                    {state.appliedCoupon &&
                      state.appliedCoupon.rules.maxCouponItems}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Drawer>
        <Snackbar
          open={snack}
          autoHideDuration={4000}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          onClose={() => setSnack(!snack)}
        >
          <Alert
            iconMapping={{
              info: <Delete fontSize="inherit" />,
            }}
            severity="error"
          >
            <strong>Cupom inválido para os itens adicionados</strong>
          </Alert>
        </Snackbar>
        <Box
          style={{
            backgroundColor: "#ecf7ff",
            padding: "15px",
            borderRadius: "5px",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: "600",
            }}
          >
            <DiscountIcon
              color="primary"
              sx={{
                marginRight: "5px",
                marginBottom: "-8px",
              }}
            />
            Possui um cupom de desconto?
          </Typography>
        </Box>
        <Box
          sx={{
            marginTop: "25px",
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={8} md={11}>
              {!state.appliedCoupon ? (
                <TextField
                  disabled={state.appliedCoupon}
                  onChange={(e) => setCouponCode(e.target.value)}
                  autoComplete="off"
                  defaultValue={
                    state.appliedCoupon
                      ? state.appliedCoupon.rules.couponCode
                      : couponCode
                  }
                  id="default-textarea"
                  label="Digite o código do cupom"
                  variant="outlined"
                  fullWidth
                  sx={{
                    mb: 2,
                  }}
                />
              ) : (
                <Alert
                  iconMapping={{
                    info: <Delete fontSize="inherit" />,
                  }}
                  severity="success"
                  sx={{
                    marginBottom: "15px",
                  }}
                >
                  <Box>
                    <strong>
                      {state.appliedCoupon && state.appliedCoupon.title}
                    </strong>
                  </Box>
                  <Box>
                    {state.appliedCoupon && state.appliedCoupon.description}
                  </Box>
                  <Button
                    startIcon={<NewReleasesIcon />}
                    variant="outlined"
                    size="small"
                    sx={{
                      marginTop: "10px",
                    }}
                    onClick={() => handleOpenDrawer()}
                  >
                    Ver Regras do Cupom
                  </Button>
                </Alert>
              )}
            </Grid>
            <Grid
              item
              xs={4}
              md={1}
              sx={{
                textAlign: "center",
              }}
            >
              {state.appliedCoupon ? (
                <IconButton
                  onClick={() => removeCoupon()}
                  variant="contained"
                  color="primary"
                  sx={{
                    mr: 1,
                  }}
                >
                  <RemoveCircle />
                </IconButton>
              ) : (
                <Button
                  onClick={() => checkCoupon()}
                  variant="contained"
                  fullWidth
                  color="primary"
                  startIcon={<ArrowForwardIosRounded />}
                  sx={{
                    height: "55px",
                  }}
                >
                  Aplicar
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
        {/* <Box
          style={{
            marginBottom: "15px",
            border: "1px solid #ecf7ff",
            padding: "15px",
          }}
        ></Box> */}
      </>
    </Grid>
  );
};
export default CouponInfo;
