import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  Button,
  Divider,
  Snackbar,
  Alert,
  Chip,
} from "@mui/material";
import { Link, NavLink, useParams, useNavigate } from "react-router-dom";
import { useApp } from "../../context/appContext";
import calculateTotalPrice from "../../utils/calculateTotalCart";
import currency from "currency-formatter";
import { Delete } from "@mui/icons-material";
import CouponInfo from "../checkout/couponInfo";
import FilterFramesIcon from "@mui/icons-material/FilterFrames";

const CartCheckout = (props) => {
  // 2

  const [snack, setSnack] = useState(false);

  const { restaurantName } = useParams();

  const navigate = useNavigate();

  const { state, updateCart, registerSessionActivity } = useApp();

  const handleRemoveFromCart = (cart_id) => {
    setSnack(true);

    /* //console.log("carrinho");
    //console.log(state.cart); */

    const updatedCart = state.cart.filter((i) => i.cart_id !== cart_id);

    updateCart(updatedCart);
    //setSnack(false);
  };

  useEffect(() => {
    //console.log("cart", state.cart);
  }, []);

  useEffect(() => {
    /* registerSessionActivity(
      {
        type: "CART_VIEW",
        branch: state.branch._id,
        cart: state.cart,
      },
      state.branch
    ); */
  }, []);

  return (
    <>
      <Snackbar
        open={snack}
        autoHideDuration={4000}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        onClose={() => setSnack(!snack)}
      >
        <Alert
          iconMapping={{
            info: <Delete fontSize="inherit" />,
          }}
          severity="info"
        >
          <strong>Produto removido do carrinho</strong>
        </Alert>
      </Snackbar>
      <Box
        sx={{
          width: "100%",
          paddingLeft: {
            xs: "0px !important",
            md: "0px !important",
          },
          paddingRight: {
            xs: "25px !important",
            md: "25px !important",
          },
        }}
      >
        <Grid container spacing={0}>
          {/* ------------------------- row 1 ------------------------- */}

          {state.cart.length > 0 ? (
            <>
              <Grid
                item
                xs={12}
                lg={12}
                style={{
                  width: "100%",
                  marginBottom: "0",
                }}
              >
                <Divider
                  sx={{
                    marginTop: "25px",
                    marginBottom: "15px",
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "600",
                    color: "#CCC",
                  }}
                >
                  <FilterFramesIcon
                    sx={{
                      marginRight: "5px",
                      marginBottom: "-8px",
                    }}
                  />{" "}
                  Resumo do seu pedido
                </Typography>
                <Divider
                  sx={{
                    width: "100%",
                    marginTop: "15px",
                  }}
                />
              </Grid>
              {state.cart.map((item) => (
                <>
                  <Grid
                    id={item.cart_id}
                    xs={12}
                    lg={12}
                    style={{ marginTop: "25px", width: "100%" }}
                  >
                    <>
                      <Box
                        style={{
                          backgroundColor: "#f5f5f5",
                          padding: "15px",
                          borderRadius: "5px",
                          width: "100%",
                        }}
                      >
                        <Grid
                          container
                          spacing={0}
                          sx={{
                            width: "100%",
                          }}
                        >
                          <Grid item xs={10} lg={10}>
                            <Typography
                              variant="h6"
                              sx={{
                                fontWeight: "600",
                              }}
                            >
                              {item.quantity}x {item.name}
                            </Typography>
                            {item.isOffer &&
                              item.offer &&
                              item.offer.rules.isCoupon && (
                                <Typography
                                  sx={{
                                    fontSize: "10px",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bolder",
                                    }}
                                  >
                                    OFERTA APLICADA:
                                  </span>{" "}
                                  {item.offer.title}
                                </Typography>
                              )}
                          </Grid>
                          <Grid
                            display="flex"
                            justifyContent="flex-end"
                            item
                            xs={2}
                            lg={2}
                          >
                            <Typography
                              variant="h6"
                              sx={{
                                fontWeight: "600",
                              }}
                            >
                              {currency.format(
                                item.isOffer
                                  ? item.offerPrice * item.quantity
                                  : item.amount * item.quantity,
                                {
                                  code: "BRL",
                                }
                              )}
                            </Typography>
                            {/* <Delete
                              onClick={() => handleRemoveFromCart(item.cart_id)}
                            /> */}
                          </Grid>
                        </Grid>
                      </Box>
                      {item.complements.length > 0 && (
                        <Box
                          style={{
                            marginBottom: "15px",
                            border: "1px solid #f5f5f5",
                            padding: "15px",
                          }}
                        >
                          {item.complements.map((complement) => (
                            <>
                              <Grid container spacing={0}>
                                <Grid item xs={10} lg={10}>
                                  <Typography
                                    variant="h6"
                                    sx={{
                                      fontWeight: "600",
                                      color: "#bbbbbb",
                                    }}
                                  >
                                    {complement.name}
                                  </Typography>
                                </Grid>
                                <Grid
                                  display="flex"
                                  justifyContent="flex-end"
                                  item
                                  xs={2}
                                  lg={2}
                                >
                                  <Typography
                                    variant="h6"
                                    sx={{
                                      fontWeight: "600",
                                      color: "#bbbbbb",
                                    }}
                                  >
                                    {complement.mandatory ? "Obrigatório" : ""}
                                  </Typography>
                                </Grid>
                              </Grid>
                              {complement.items.map((item) => (
                                <Grid container spacing={0}>
                                  <Grid item xs={10} lg={10}>
                                    <Typography variant="h6">
                                      {item.quantity}x - {item.name}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    display="flex"
                                    justifyContent="flex-end"
                                    item
                                    xs={2}
                                    lg={2}
                                  >
                                    <Typography variant="h6">
                                      {currency.format(
                                        item.price * item.quantity,
                                        {
                                          code: "BRL",
                                        }
                                      )}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              ))}
                              <Divider
                                style={{
                                  marginTop: "15px",
                                  marginBottom: "15px",
                                }}
                              />
                            </>
                          ))}
                        </Box>
                      )}
                      <Box
                        style={{
                          padding: "0 15px",
                        }}
                      >
                        {item.notes !== "" && (
                          <Grid item xs={12} lg={12}>
                            <Typography
                              variant="h6"
                              sx={{
                                fontWeight: "600",
                                color: "#eb9696",
                              }}
                            >
                              Observações
                            </Typography>
                            <Typography variant="h6">{item.notes}</Typography>
                          </Grid>
                        )}
                      </Box>
                    </>
                  </Grid>
                </>
              ))}
              {/* <Grid item xs={12} lg={12}>
                <CouponInfo />
              </Grid> */}
            </>
          ) : (
            <Box
              style={{
                marginBottom: "0",
                marginTop: "50px",
              }}
            >
              <Typography
                variant="h3"
                style={{ textAlign: "center", marginBottom: "15px" }}
              >
                Você não possui itens no seu carrinho
              </Typography>
            </Box>
          )}
          <Box style={{ width: "100%", marginTop: "25px" }}>
            {props.fromDrawer ? (
              <Button
                onClick={() => {
                  props.closeCartDrawer();
                  navigate(`/${restaurantName}/menu`);
                }}
                variant="outlined"
                color="primary"
                fullWidth
                style={{ marginBottom: "15px" }}
              >
                Adicionar mais itens
              </Button>
            ) : (
              <Link to={`/${restaurantName}/menu`}>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  style={{ marginBottom: "15px" }}
                >
                  Adicionar mais itens
                </Button>
              </Link>
            )}
            {/* <Button onClick={() => //console.log(state)}>STATE</Button> */}
          </Box>
          {/* ------------------------- row 2 ------------------------- */}
          {/* <Grid item xs={12} lg={4}>
          <DailyActivities />
        </Grid>
        <Grid item xs={12} lg={8}>
          <ProductPerformance />
        </Grid> */}
          {/* ------------------------- row 3 ------------------------- */}
        </Grid>
      </Box>
    </>
  );
};

export default CartCheckout;
