import axios from "axios";
import translateUF from "./translateStates";

const googleFilter = (place, filter) => {
  var field = "";
  for (var i = 0; i < place.address_components.length; i++) {
    for (var j = 0; j < place.address_components[i].types.length; j++) {
      if (place.address_components[i].types[j] == filter) {
        field = place.address_components[i];
      }
    }
  }
  ////console.log("field", field);
  return field.short_name;
};

const addressByCEP = async (cep) => {
  //url https://nominatim.openstreetmap.org/search?format=json&postalcode=60410100&addressdetails=1

  /* const apiEndPoint = `https://nominatim.openstreetmap.org/search?q=${cep}&addressdetails=1&format=json`;

  const result = await axios(apiEndPoint); */

  ////console.log("apiEndPoint", apiEndPoint);

  ////console.log("result point by address", result);

  /* if (result.data.length) {
    const results = result.data.map((res) => {
      const address = res.address;

      //formatted_address
      let addressOutput = {};

      //TRATAR OS DADOS DO RETORNO

      let googleResult = {}; */

  /* if (!address.road) {
        //NÃO ACHOU A RUA, TENTAR NO GOOGLE
        const googleEndpoint = `https://maps.googleapis.com/maps/api/geocode/json?address=${cep}&key=AIzaSyBMxjtrWtCzycDYb3KQvc9vF7nNVeC4KaU`;
        const result = await axios(googleEndpoint);
        //////console.log("google result", result.data);

        if (result.data && result.data.results && result.data.results.length) {
          const googleResult = result.data.results[0];
          ////console.log("google result", googleResult);
          addressOutput.street = googleResult.address_components[2].long_name;
        }
      } */

  /* if (res.addresstype === "road") {
        addressOutput.street = address.road;
      }
      if (res.addresstype === "building") {
        addressOutput.street = address.road;
        addressOutput.number = address.house_number;
      }
      if (address.suburb) {
        addressOutput.neighborhood = address.suburb;
      }
      if (address.neighbourhood) {
        addressOutput.neighbourhood = address.neighbourhood;
      }
      if (address.postcode) {
        addressOutput.postcode = address.postcode;
      }
      if (address.city) {
        addressOutput.city = address.city;
      }
      if (address.city_district) {
        addressOutput.city = address.town;
      }
      if (address.town) {
        addressOutput.city = address.city_district;
      }
      if (address.state) {
        addressOutput.state = address.state;
      }

      return {
        name: res.display_name,
        lat: res.lat,
        lon: res.lon,
        address: addressOutput,
      };
    });
    return results;
  } else { */
  //CHAMAR GOOGLE AQUI - TESTAR CEP
  //GOOGLE MAPS API
  const googleEndpoint = `https://maps.googleapis.com/maps/api/geocode/json?address=${cep}&key=AIzaSyBMxjtrWtCzycDYb3KQvc9vF7nNVeC4KaU`;
  const result = await axios(googleEndpoint);
  //console.log("google result", result.data);

  const results = result.data.results.map((res) => {
    //TREAT DATA AND OUTPUT THE ADDRESS OBJECT

    const street = googleFilter(res, "route");
    const number = googleFilter(res, "street_number");

    const neighborhood =
      googleFilter(res, "sublocality_level_1") ||
      googleFilter(res, "administrative_area_level_4");
    const postalCode = googleFilter(res, "postal_code");
    const city = googleFilter(res, "administrative_area_level_2");
    const uf = googleFilter(res, "administrative_area_level_1");

    let addressOutput = {};

    if (street) {
      addressOutput.street = street;
    }
    if (number) {
      addressOutput.number = number;
    }
    if (neighborhood) {
      addressOutput.neighborhood = neighborhood;
    }
    if (postalCode) {
      addressOutput.postcode = postalCode.replace(/\D/g, "");
    }
    if (city) {
      addressOutput.city = city;
    }
    if (uf) {
      addressOutput.state = translateUF(uf);
    }

    //console.log("addressOutput", addressOutput);

    return {
      name: res.formatted_address,
      lat: res.geometry.location.lat,
      lon: res.geometry.location.lng,
      address: addressOutput,
    };
  });
  return results;
  /* return [];
  } */
};

const suggestAddress = async (address) => {
  const apiEndPoint = `https://nominatim.openstreetmap.org/search?q=${address}&addressdetails=1&format=json`;

  const result = await axios(apiEndPoint);

  ////console.log("apiEndPoint", apiEndPoint);

  ////console.log("result point by address", result);

  //1000 - IT WILL NOT BE GREATHER, SO WE'RE SEARCHING GOOGLE INSTEAD (FOR TEST PURPOSES)
  if (result.data.length && result.data.length > 1000) {
    //check for other fields //result.data[0].
    const results = result.data.map((res) => {
      const address = res.address;

      //formatted_address

      let addressOutput = {};

      //TRATAR OS DADOS DO RETORNO

      if (res.addresstype === "road") {
        addressOutput.street = address.road;
      }
      if (res.addresstype === "building") {
        addressOutput.street = address.road;
        addressOutput.number = address.house_number;
      }
      if (address.suburb) {
        addressOutput.neighborhood = address.suburb;
      }
      if (address.neighbourhood) {
        addressOutput.neighbourhood = address.neighbourhood;
      }
      if (address.postcode) {
        addressOutput.postcode = address.postcode;
      }
      if (address.city) {
        addressOutput.city = address.city;
      }
      if (address.city_district) {
        addressOutput.city = address.town;
      }
      if (address.town) {
        addressOutput.city = address.city_district;
      }
      if (address.state) {
        addressOutput.state = address.state;
      }

      return {
        name:
          address && address.house_number
            ? `${address.road}, ${address.house_number}, ${
                address.suburb || address.neighbourhood
              } - ${address.city || address.city_district || address.town}, ${
                address.state
              }`
            : `${address.road}, ${address.suburb || address.neighbourhood} - ${
                address.city || address.city_district || address.town
              }, ${address.state}`,
        lat: res.lat,
        lon: res.lon,
        address: addressOutput,
      };
    });
    return results;
  } else {
    ////console.log("buscando google");

    //GOOGLE MAPS API
    const googleEndpoint = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyBMxjtrWtCzycDYb3KQvc9vF7nNVeC4KaU`;
    const result = await axios(googleEndpoint);
    //console.log("google result", result.data);

    const results = result.data.results.map((res) => {
      //TREAT DATA AND OUTPUT THE ADDRESS OBJECT

      const street = googleFilter(res, "route");
      const number = googleFilter(res, "street_number");

      const neighborhood =
        googleFilter(res, "sublocality_level_1") ||
        googleFilter(res, "administrative_area_level_4");
      const postalCode = googleFilter(res, "postal_code");
      const city = googleFilter(res, "administrative_area_level_2");
      const uf = googleFilter(res, "administrative_area_level_1");

      let addressOutput = {};

      if (street) {
        addressOutput.street = street;
      }
      if (number) {
        addressOutput.number = number;
      }
      if (neighborhood) {
        addressOutput.neighborhood = neighborhood;
      }
      if (postalCode) {
        addressOutput.postcode = postalCode.replace(/\D/g, "");
      }
      if (city) {
        addressOutput.city = city;
      }
      if (uf) {
        addressOutput.state = translateUF(uf);
      }

      //console.log("addressOutput", addressOutput);

      return {
        name: res.formatted_address,
        lat: res.geometry.location.lat,
        lon: res.geometry.location.lng,
        address: addressOutput,
      };
    });
    return results;
  }
};

const pointByAddress = async (address) => {
  const apiEndPoint = `https://nominatim.openstreetmap.org/search?q=${address}&format=json`;

  const result = await axios(apiEndPoint);

  ////console.log("apiEndPoint", apiEndPoint);

  ////console.log("result point by address", result);

  if (result.data.length) {
    return {
      lat: result.data[0].lat,
      lon: result.data[0].lon,
    };
  } else {
    ////console.log("buscando google");

    //GOOGLE MAPS API
    const googleEndpoint = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyBMxjtrWtCzycDYb3KQvc9vF7nNVeC4KaU`;
    const result = await axios(googleEndpoint);
    ////console.log("google result", result.data);

    if (result.data && result.data.results.length) {
      const resultsGoogle = result.data.results[0];

      const position = {
        lat: resultsGoogle.geometry.location.lat,
        lon: resultsGoogle.geometry.location.lng,
      };

      return position;
    }
  }
};

const addressByPoint = async (lat, lon) => {
  const apiEndPoint = `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lon}&format=json`;
  const result = await axios(apiEndPoint);

  //////console.log("apiendpoint", apiEndPoint);
  ////console.log("result address by point", result);

  if (result.data) {
    //////console.log("reverse geocoding", result.data[0]);
    return result.data;
  } else {
    //GOOGLE MAPS API
    /* const googleEndpoint = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=AIzaSyBMxjtrWtCzycDYb3KQvc9vF7nNVeC4KaU`;
  const result = await axios(googleEndpoint);

  ////console.log("google result by point", result.data); */
  }
};

export { pointByAddress, addressByPoint, suggestAddress, addressByCEP };
